/*
 * Copyright 2020-2022 Enghouse Interactive, Inc. All Rights Reserved.
 * This software is the proprietary information of Enghouse Interactive, Inc.
 * Use is subject to license terms.
 *
 */

import store from "../../../store";
import { sdConfig } from "../../smartdial-config.js";
import { Notice } from "view-design";
import { i18n } from "@/plugins/language";
import CcpService from "@/common/services/ccp.service";
import { getTimeObject } from "@/utils/time/getTimeObject";

export class smartdialcore {
  constructor() {
    if (smartdialcore.exists) {
      return smartdialcore.instance;
    }
    smartdialcore.instance = this;
    smartdialcore.exists = true;
    this.core = SDCore;
    this.timer = null;
  }

  init = function (agent) {
    if (smartdialcore.exists && this.core.$scope._queueConnectTimeout) {
      clearTimeout(this.core.$scope._queueConnectTimeout);
    }
    sdConfig.onError = this.errorRecieved;
    sdConfig.region = store.state.agent?.agent?.Region;
    sdConfig.identityPoolId = `${sdConfig.region}:${store.state.currentUser?.account?.CognitoPoolId}`;
    sdConfig.ccpConfig.ccpUrl = `https://${localStorage.getItem("ccpUrl")}/connect/ccp-v2/`;
    if (store.state.agent?.agent?.Region && store.state.currentUser?.account?.CognitoPoolId) {
      try {
        this.core.init(sdConfig, agent);
      } catch (error) {
        console.error(error);
      }
    }
  };

  disconectAgent() {
    if (smartdialcore.exists) {
      clearTimeout(this.core.$scope._queueConnectTimeout);
    }
    if (this.core.$scope._callCenterQueueUrl)
      this.core.$scope._sendQueueMessage(this.core.$scope._callCenterQueueUrl, "Disconnect", this.core.$scope._agentID);
    this.core.$scope.isInitialized = false;
  }

  errorRecieved(message) {
    Notice.error({ title: i18n.t("engage.smartDial.errorInitializingSmartDial"), desc: message });
  }

  isInitialized() {
    return this.core.$scope.isInitialized;
  }

  handleContactOnACW() {
    let limit = store.state.currentUser?.profile?.PhoneConfig?.AfterContactWorkTimeLimit;
    if (
      this.core.$scope._connectAgent.getState().name === "FailedConnectCustomer" ||
      this.core.$scope._connectAgent.getState().name === "Preview"
    ) {
      this.goToAvailable();
    }
    if (limit > 0) {
      this.timer = setTimeout(() => {
        this.resetSmartDialTab();
        if (this.core.$scope._connectAgent.getState().name === "AfterCallWork") {
          this.goToAvailable();
        }
        this.timer = null;
      }, limit * 1000);
    } else {
      this.resetSmartDialTab();
    }
  }

  resetSmartDialTab() {
    store.commit("contact/setSmartDialContact", { status: "init", contactInformation: {}, accountConnected: "init" });
  }

  dispositionAccount(agentResult) {
    this.core.dispositionAccount(agentResult);
    this.resetSmartDialTab();
  }

  goToAvailable() {
    CcpService.setAgentToAvailable();
  }
}

const SDCore = (function () {
  function SmartDial() {}

  const sdVersion = "9.5.7";
  const sdRevision = "202202220908";

  let $scope = {
    isInitialized: false,
    sqs: null,

    // Callback Functions
    _onConnectedCallback: null,
    _onDisconnectedCallback: null,
    _onCallCallback: null,
    _onPreviewCallback: null,
    _onAudioNeededCallback: null,
    _onAudioNotNeededCallback: null,
    _onUserNotificationCallback: null,
    _onStateChangedCallback: null,
    _onListTransitionCallback: null,
    _onPreviewAcceptedCallback: null,
    _onCallPreviewCallback: null,
    _onCallPreviewFailedCallback: null,
    _onFlagsChangedCallback: null,
    _onAllowTransferAnsweringMachineCallback: null,
    _onActivateCallBack: null,
    _throwError: null,
    _busyStateName: "Busy",
    _previewStateName: "Preview",
    _availableStateName: "Available", // Default: "Available"
    _offlineStateName: "", // Default: "Offline"

    _connectLogging: false,
    _messageDebug: false,

    _isConnecting: false,
    _isConnected: false,
    isConnectingMode: false, // UI?  (also used in _handleAgentAfterCallWork)

    _agentID: null,
    _connectAgent: null,
    _agentConfig: null,
    _connectInstanceID: null,
    _agentQueueName: null,
    _agentQueueUrl: null,
    _callCenterQueueName: null,
    _contactData: {
      contact: null,
      sendWrapUp: false,
      isAborted: false,
    },
    _managePreviewAudio: false,
    _previewInfoNumber: "",
    _agentResultList: [],
    _audioPIN: "",
    _disableOutbound: false,
    _agentProperties: [],
    _queueConnectTimeout: null,
  };

  $scope._debug = function (message) {
    if ($scope._messageDebug) window.connect.getLog().info(message);
  };

  $scope._info = function (message) {
    window.connect.getLog().info(message);
  };

  $scope._warning = function (message) {
    window.connect.getLog().warn(message);
  };

  $scope._error = function (message) {
    window.connect.getLog().error(message);
  };

  $scope._resolveCallCenterQueueUrl = function () {
    let params = {
      QueueName: $scope._callCenterQueueName,
    };

    $scope.sqs.getQueueUrl(params, function (err, data) {
      if (err) $scope._error(`Failed to get Queue URL for the CallCenter queue: ${err}`);
      else {
        $scope._info(`CallCenter Queue URL: ${data.QueueUrl}`);

        $scope._callCenterQueueUrl = data.QueueUrl;
      }
    });
  };

  $scope._createAgentQueue = function (agent) {
    // Normalize the user name for use in an SQS queue name
    let agentUserName = $scope._agentConfig.username;

    agentUserName = agentUserName.replace(/[^a-zA-Z0-9_]/g, "_"); // Replace invalid characters with an underscore
    agentUserName = agentUserName.replace(/_+/g, "_"); // Replace multiple underscores with a single underscore
    agentUserName = agentUserName.substring(0, 28); // Truncate to fit the space available in our generated name

    // Calculate a timestamp to create a unique queue name for each browser instance
    let timeStamp = getTimeObject().getOffsetTime();
    let secondsFromMidnight = timeStamp.getHours() * 60 * 60 + timeStamp.getMinutes() * 60 + timeStamp.getSeconds();

    $scope._agentQueueName = `SD_${$scope._connectInstanceID}_${agentUserName}_${secondsFromMidnight}.fifo`;

    $scope._info(`#SSD - agentQueueName: ${$scope._agentQueueName}`);

    // Create the agent queue; if it already exists, SQS returns the QueueUrl of the existing queue
    let createQueueParams = {
      QueueName: $scope._agentQueueName,
      Attributes: {
        FifoQueue: "true",
        VisibilityTimeout: "1",
      },
    };

    $scope.sqs.createQueue(createQueueParams, function (err, data) {
      if (err) $scope._error(`Failed to create agent queue: ${err}`);
      else {
        $scope._agentQueueUrl = data.QueueUrl;

        $scope._info(`#SSD - Queue created. Agent Queue URL: ${$scope._agentQueueUrl}`);

        // Start waiting for messages
        $scope._waitForQueueMessage($scope._agentQueueUrl, 2);
      }
    });
  };

  $scope._deleteAgentQueue = function () {
    let agentQueueUrl = $scope._agentQueueUrl;

    $scope._agentQueueName = null;
    $scope._agentQueueUrl = null;

    if (agentQueueUrl) {
      let deleteQueueParams = {
        QueueUrl: agentQueueUrl,
      };

      $scope.sqs.deleteQueue(deleteQueueParams, function (err, data) {
        if (err) $scope._error(`Failed to delete agent queue: ${err}`);
        else $scope._info(`#SSD - Queue deleted. Agent Queue Url: ${agentQueueUrl}`);
      });
    }
  };

  $scope._sendQueueMessage = function (queueUrl, message, agentID, extraAttributes) {
    if (queueUrl === null) return;

    $scope._info(`#SSD - [${agentID}] Send [${message}] to queue [${queueUrl}]`);

    if (queueUrl === undefined) {
      window.connect.getLog.info(`queueUrl is undefined; callCenterQueueUrl: ${$scope._callCenterQueueUrl}`);

      // Wait to see if the QueueUrl gets resolved in a moment
      setTimeout(function () {
        $scope._info(`#SSD - After wait; callCenterQueueUrl = ${$scope._callCenterQueueUrl}`);

        // Try to resend the message
        $scope._sendQueueMessage($scope._callCenterQueueUrl, message, agentID, extraAttributes);
      }, 2000);
      return;
    }

    let sendMessageParams = {
      QueueUrl: queueUrl,
      MessageGroupId: agentID,
      MessageDeduplicationId: agentID + Date.now(),
      MessageBody: message,
      DelaySeconds: 0,
      MessageAttributes: {
        AgentID: {
          DataType: "String",
          StringValue: agentID,
        },
      },
    };

    if (extraAttributes) {
      // Add the additional property to the object
      for (const key in extraAttributes) {
        let value = extraAttributes[key];

        if (Object.prototype.hasOwnProperty.call(extraAttributes, key)) {
          sendMessageParams.MessageAttributes[key] = value;
        }
      }
    }

    $scope.sqs.sendMessage(sendMessageParams, function (err, data) {
      if (err) $scope._error(`Failed to send message to the SQS queue: ${err}`);
      else $scope._debug(`Message sent successfully: ${JSON.stringify(data)}`);
    });
  };

  $scope._sendConnect = function () {
    if (!$scope._isConnected && !$scope._isConnecting && !$scope._disableOutbound) {
      if (!$scope._agentQueueName) {
        $scope._info("#SSD - The agent queue has not been created. Creating queue and trying it again...");

        $scope._createAgentQueue();

        $scope._queueConnectTimeout = setTimeout($scope._sendConnect, 1000);
      } else if (!$scope._agentQueueUrl) {
        $scope._info("#SSD - The agent queue URL has not been resolved. Trying it again...");

        $scope._queueConnectTimeout = setTimeout($scope._sendConnect, 1000);
      } else if ($scope._callCenterQueueUrl && $scope._connectAgent && $scope._agentConfig) {
        $scope._isConnecting = true;

        let connectAttributes = {
          AgentQueueUrl: { DataType: "String", StringValue: $scope._agentQueueUrl },
          AgentState: { DataType: "String", StringValue: $scope._connectAgent.getStatus().name },
          RoutingProfile: { DataType: "String", StringValue: JSON.stringify($scope._agentConfig.routingProfile) },
        };

        $scope._debug("connectAttributes: " + JSON.stringify(connectAttributes));

        $scope._sendQueueMessage($scope._callCenterQueueUrl, "Connect", $scope._agentID, connectAttributes);
      } else $scope._warning("The CallCenter queue URL has not been resolved!");
    }
  };

  $scope._waitForQueueMessage = function (queueUrl, waitTime) {
    function handleReceiveMessage(err, data) {
      if (err) {
        $scope._error(`Failed to receive message from the SQS queue: ${err}`);

        if (err.retryable) {
          setTimeout($scope._waitForQueueMessage(queueUrl, 10), 100);
        }
      } else {
        let waitAgain = true;

        if (data.Messages.length > 0) {
          $scope._debug(`Received message: ${JSON.stringify(data)}`);

          _processReceivedMessage(data.Messages);
        } else if ($scope._isConnecting) {
          $scope._isConnecting = false;

          // The connect failed, so throw out the queue and don't bother waiting for another message
          $scope._deleteAgentQueue();
          waitAgain = false;
        }

        if (waitAgain) {
          // And wait again...
          setTimeout($scope._waitForQueueMessage(queueUrl, 10), 100);
        }
      }
    }

    function _processPreviewMessage(aMessage, previewAccount) {
      let goToPreview = true;
      let agentState;

      agentState = $scope._connectAgent.getStatus();
      if (agentState.type === window.connect.AgentStateType.ROUTABLE || agentState.name === $scope._previewStateName) {
        if ($scope._managePreviewAudio) {
          //** *TODO: When to clear the $scope._previewInfoNumber?
          $scope._previewInfoNumber = previewAccount.Account.Phone.Phone;
          $scope._managePreviewAccepted = false;

          if ($scope._contactData && $scope._contactData.contact) {
            $scope._info(`Contact ${$scope._contactData.contact} state: ${$scope._contactData.contact.getStatus()}`);

            let agentConn = $scope._contactData.contact.getAgentConnection();
            if (agentConn) {
              goToPreview = false;

              agentConn.destroy({
                success: function () {
                  $scope._info(` => AccountPreview: Disconnected contact ${$scope._contactData.contact.getContactId()}`);

                  $scope._goToState($scope._previewStateName);
                  if ($scope._onPreviewCallback)
                    $scope._onPreviewCallback(JSON.parse(aMessage.MessageAttributes.Account.StringValue));
                },
                failure: function () {
                  $scope._info(
                    ` => AccountPreview: Failed to disconnect contact ${$scope._contactData.contact.getContactId()}`
                  );

                  $scope._goToState($scope._previewStateName);
                  if ($scope._onPreviewCallback)
                    $scope._onPreviewCallback(JSON.parse(aMessage.MessageAttributes.Account.StringValue));
                },
              });
            } else $scope._info("No agent connection");
          }
        }

        $scope._contactData.previewAbortCode = null;

        if (goToPreview) {
          $scope._goToState($scope._previewStateName);

          if ($scope._onPreviewCallback) {
            $scope._onPreviewCallback(JSON.parse(aMessage.MessageAttributes.Account.StringValue));
          }
        }
      } else if (
        agentState.name === window.connect.AgentErrorStates.FAILED_CONNECT_AGENT ||
        agentState.name === window.connect.AgentErrorStates.FAILED_CONNECT_CUSTOMER
      ) {
        $scope._info(`Agent is in state ${agentState.type}, processing of AccountPreview delayed`);
        setTimeout(function () {
          _processPreviewMessage(aMessage, previewAccount);
        }, 250);
      } else {
        $scope._warning(
          `Received AccountPreview, but agent is not in the correct state! Rejecting the preview account. Status: ${JSON.stringify(
            agentState
          )}`
        );
        let params = {
          ResultDelay: { DataType: "String", StringValue: "1" },
        };

        $scope._sendQueueMessage($scope._callCenterQueueUrl, "RejectAccount", $scope._agentID, params);
      }
    }

    function _processReceivedMessage(messages) {
      let arrayLen = messages.length;

      for (let i = 0; i < arrayLen; i += 1) {
        let aMessage = messages[i];

        $scope._info(`Processing Message ${i + 1} of ${messages.length}: ${aMessage.Body}`);

        if (aMessage.Attributes && aMessage.Attributes.MessageGroupId)
          $scope._debug(`->Message group ID: ${aMessage.Attributes.MessageGroupId}`);

        _deleteMessage(aMessage);

        let messageBody = aMessage.Body;

        if (messageBody.match(/Disconnected/i)) {
          $scope._isConnecting = false;
          $scope._isConnected = false;
          $scope._applicationName = "";
          $scope._audioAddress = "";
          $scope._managePreviewAudio = false;
          $scope._managePreviewAccepted = false;

          $scope._info("Disconnected from the dialer.");
          $scope.setInfoMessage("Disconnected from the dialer.");
          $scope._deleteAgentQueue($scope._agentQueueUrl);

          if ($scope._onDisconnectedCallback) $scope._onDisconnectedCallback();
        } else if (messageBody.match(/Connected/i)) {
          $scope._isConnecting = false;
          $scope._isConnected = true;
          $scope.hasLoggedOut = false;

          if (aMessage.MessageAttributes) {
            $scope._agentProperties = [];
            for (const propertyName in aMessage.MessageAttributes) {
              if (
                propertyName[0] !== "@" &&
                propertyName[0] !== "#" &&
                Object.prototype.hasOwnProperty.call(aMessage.MessageAttributes, propertyName)
              ) {
                $scope._agentProperties.push({
                  propertyName: propertyName.toUpperCase(),
                  value: aMessage.MessageAttributes[propertyName].StringValue,
                });
              }
            }

            if (aMessage.MessageAttributes.Application)
              $scope._applicationName = aMessage.MessageAttributes.Application.StringValue;
            if (aMessage.MessageAttributes.AudioAddress)
              $scope._audioAddress = aMessage.MessageAttributes.AudioAddress.StringValue;
            if (aMessage.MessageAttributes.ManagePreviewAudio) {
              if (aMessage.MessageAttributes.ManagePreviewAudio.StringValue === "True") $scope._managePreviewAudio = true;
              else $scope._managePreviewAudio = false;
            }
          }

          $scope._info(
            `Connected to the dialer<br/>-> Application: ${$scope._applicationName} <br/>-> Audio Address: ${$scope._audioAddress} <br/>-> Manage Preview Audio: ${$scope._managePreviewAudio}`
          );
          $scope.setInfoMessage("Agent is connected to the dialer.");
          if ($scope._onConnectedCallback) $scope._onConnectedCallback();
        } else if (messageBody.match(/Activate/i)) {
          $scope._info("Activated on the dialer");
          if ($scope._onActivateCallBack) {
            let options = {
              groupName: null,
              listName: null,
              mode: {
                inbound: false,
                outboundPreview: false,
                outboundPower: false,
                outboundPredictive: false,
                outboundPassive: false,
              },
            };

            if (aMessage.MessageAttributes.GroupName) options.groupName = aMessage.MessageAttributes.GroupName.StringValue;
            if (aMessage.MessageAttributes.ListName) options.listName = aMessage.MessageAttributes.ListName.StringValue;
            if (aMessage.MessageAttributes.Mode) {
              let mode = aMessage.MessageAttributes.Mode.StringValue;
              if (mode & RCS_AGENT_INBOUND) options.mode.inbound = true;

              if (mode & RCS_AGENT_OUTBOUND_PREVIEW) options.mode.outboundPreview = true;
              else if (mode & RCS_AGENT_OUTBOUND_POWER) options.mode.outboundPower = true;
              else if (mode & RCS_AGENT_OUTBOUND_PREDICTIVE) options.mode.outboundPredictive = true;
              else if (mode & RCS_AGENT_OUTBOUND_PASSIVE) options.mode.outboundPassive = true;
            }

            $scope._onActivateCallBack(options);
          }
        } else if (messageBody.match(/ResultList/i)) {
          if (aMessage.MessageAttributes.Results) {
            try {
              let agentResultList = JSON.parse(aMessage.MessageAttributes.Results.StringValue);

              $scope._updateAgentResultList(agentResultList, () => {
                $scope._info("Unable to locate the result selection element!");
              });
              $scope.setInfoMessage("Updated Agent's results list");
              $scope._info("Updated the Agent results with new values from the server.");
            } catch (err) {
              $scope._warning(
                `Received Agent results that were NOT in a valid JSON format! Value: ${aMessage.MessageAttributes.Results.StringValue}`
              );

              $scope._agentResultList = []; // Make sure it's an array
            }
          }
        } else if (messageBody.match(/AudioNeeded/i)) {
          // Call the dialer
          if (aMessage.MessageAttributes.AudioPIN) $scope._audioPIN = aMessage.MessageAttributes.AudioPIN.StringValue;
          store.commit("contact/setSmartDialContact", {
            status: "connecting-account-call",
          });

          if ($scope._onAudioNeededCallback) {
            let arg = {
              audioAddress: $scope._audioAddress,
              audioPIN: $scope._audioPIN,
            };

            $scope._onAudioNeededCallback(arg);
          } else $scope._connectAgentAudio($scope._audioAddress, "account");
        } else if (messageBody.match(/AgentStateChange/i)) {
          if (aMessage.MessageAttributes.Changed.StringValue === "Data") {
            // Data state change
            let dataState = aMessage.MessageAttributes.DataState.StringValue;

            if ($scope._onStateChangedCallback) $scope._onStateChangedCallback({ data: dataState });
          } else {
            // Audio state change
            let audioState = aMessage.MessageAttributes.AudioState.StringValue;

            if (audioState.match(/Ready/i)) {
              $scope._info("Audio state change: ready!");
            } else if (audioState.match(/Active/i)) {
              $scope._info("Audio state change: active!");
            } else {
              // NotConnected
              $scope._info("Audio disconnected");
            }

            if ($scope._onStateChangedCallback) $scope._onStateChangedCallback({ audio: audioState });
          }
        } else if (messageBody.match(/AccountCall/i)) {
          $scope._info(`On Call: ${JSON.stringify(aMessage)}`);
          $scope.setInfoMessage("On Call");
          store.commit("contact/setSmartDialContact", {
            status: "connected-account-call",
            contactInformation: JSON.parse(aMessage.MessageAttributes.Account.StringValue),
            accountConnected: "on-call",
          });
          // IMPORTANT:
          $scope.isConnectingMode = false;

          //let onCallAccount = JSON.parse(aMessage.MessageAttributes.Account.StringValue);

          if ($scope._onCallCallback) {
            let account = JSON.parse(aMessage.MessageAttributes.Account.StringValue);
            account.Account.Variant = getAccountFlags(account.Account);
            $scope._onCallCallback(account);
          }
        } else if (messageBody.match(/AccountPreview/i)) {
          $scope._info(`AccountPreview: ${JSON.stringify(aMessage)}`);
          let previewAccount = JSON.parse(aMessage.MessageAttributes.Account.StringValue);
          $scope.setInfoMessage(`Preview Account: ${previewAccount?.Account?.Name}`);
          previewAccount.Account.Variant = getAccountFlags(previewAccount.Account);

          if ($scope._connectAgent) {
            // The preview processing might have to be delayed, so use a helper function
            store.commit("contact/setSmartDialContact", {
              status: "preview-incoming",
              contactInformation: { ...previewAccount },
            });
            _processPreviewMessage(aMessage, previewAccount);
          } else $scope._warning(`Amazon Connect agent object does not exist!`);
        } else if (messageBody.match(/AcceptAccount/i)) {
          // Check if the preview has already been accepted to handle a possible race condition with the server
          if (!$scope._managePreviewAccepted) smartdialcore.acceptPreview(true);
        } else if (messageBody.match(/AudioNotNeeded/i)) {
          $scope.isConnectingMode = false;
          if ($scope._onAudioNotNeededCallback) $scope._onAudioNotNeededCallback();
          else {
            if ($scope._contactData.contact) {
              $scope._info(`Hang up agent audio for contact ${$scope._contactData.contact.getContactId()}`);
              $scope._hangUpAgentAudio();
            } else $scope._info("Don't need to hang up agent's audio");
          }
        } else if (messageBody.match(/Notification/i)) {
          // The notification message is in the MessageAttributes...
          if (aMessage.MessageAttributes) {
            let notifyCode, notificationMsg;

            if (aMessage.MessageAttributes.Category) notifyCode = aMessage.MessageAttributes.Category.StringValue;
            else notifyCode = 0;

            if (aMessage.MessageAttributes.Notification) notificationMsg = aMessage.MessageAttributes.Notification.StringValue;
            else notificationMsg = null;

            $scope._info(`Notification (Code=${notifyCode}): ${notificationMsg}`);

            if (
              aMessage.MessageAttributes.DisableOutbound &&
              aMessage.MessageAttributes.DisableOutbound.StringValue === "true"
            ) {
              $scope._disableOutbound = true;
              $scope._callCenterQueueName = null;
              $scope._callCenterQueueUrl = null;
            }

            if (notificationMsg && $scope._onUserNotificationCallback) {
              $scope.setInfoMessage(notificationMsg);
              $scope._onUserNotificationCallback({ code: notifyCode, message: notificationMsg });
            }
          }
        } else if (messageBody.match(/Pending/i)) {
          $scope._info("Received Message: Pending");
        } else if (messageBody.match(/ListTransition/i)) {
          if (aMessage.MessageAttributes && aMessage.MessageAttributes.ListName) {
            let transitionMessage = "Transitioned to list " + aMessage.MessageAttributes.ListName.StringValue;

            $scope._info(transitionMessage);
            $scope.setInfoMessage(transitionMessage);

            if ($scope._onListTransitionCallback)
              $scope._onListTransitionCallback(aMessage.MessageAttributes.ListName.StringValue);
          }
        } else if (messageBody.match(/FlagsChanged/i)) {
          if ($scope._onFlagsChangedCallback && aMessage.MessageAttributes && aMessage.MessageAttributes.Flags) {
            $scope._onFlagsChangedCallback(getAgentFlags(aMessage.MessageAttributes.Flags.StringValue));
          }
        } else if (messageBody.match(/AllowTransferAnsMach/i)) {
          if ($scope._onAllowTransferAnsweringMachineCallback) {
            let canTransfer = false;
            if (aMessage.MessageAttributes && aMessage.MessageAttributes.CanTransfer) {
              canTransfer = parseInt(aMessage.MessageAttributes.CanTransfer.StringValue, 10) ? true : false;
            }
            $scope._onAllowTransferAnsweringMachineCallback(canTransfer);
          }
        } else if (messageBody.match(/TestPing/i)) {
          if ($scope.hasLoggedOut === false) {
            $scope._sendQueueMessage($scope._callCenterQueueUrl, messageBody, $scope._agentID);
          }
        } else $scope._warning(`Unrecognized message: ${messageBody}<br/>${JSON.stringify(aMessage)}`);
      }
    }

    function _deleteMessage(message) {
      let deleteParams = {
        QueueUrl: $scope._agentQueueUrl,
        ReceiptHandle: message.ReceiptHandle,
      };

      $scope.sqs.deleteMessage(deleteParams, function (err, data) {
        if (err) $scope._error(`Failed to delete message: ${err}`);
        else $scope._debug(`Message deleted: ${JSON.stringify(data)}`);
      });
    }

    $scope._debug(`Wait for a message (queue URL=${queueUrl})...`);

    let getMessageParams = {
      QueueUrl: queueUrl,
      MaxNumberOfMessages: 1,
      VisibilityTimeout: 1,
      WaitTimeSeconds: waitTime, //10,
      AttributeNames: ["All"],
      MessageAttributeNames: ["All"],
    };

    $scope.sqs.receiveMessage(getMessageParams, handleReceiveMessage);
  };

  // Activate flags
  const RCS_AGENT_INBOUND = 0x0001;
  const RCS_AGENT_OUTBOUND_PREVIEW = 0x0002;
  const RCS_AGENT_OUTBOUND_POWER = 0x0004;
  const RCS_AGENT_OUTBOUND_PREDICTIVE = 0x0008;
  const RCS_AGENT_OUTBOUND_PASSIVE = 0x0010;

  // Account Flags
  const RCS_ACCOUNT_INBOUND = 0x00000001;
  const RCS_ACCOUNT_OUTBOUND = 0x00000002;
  const RCS_ACCOUNT_IC = 0x00000004;
  const RCS_ACCOUNT_DIAL_REQUEST = 0x00000008;

  const RCS_ACCOUNT_AUTO_ACCEPT = 0x00000010;
  const RCS_ACCOUNT_PREVIEW_AUTO_ACCEPT = 0x00000020;
  const RCS_ACCOUNT_ENABLE_CALL_PROGRESS = 0x00000040;
  const RCS_ACCOUNT_ENABLE_ANS_MACH_DETECT = 0x00000080;

  const RCS_ACCOUNT_AGENT_CALL = 0x00000100;
  const RCS_ACCOUNT_CALL_NUMBER = 0x00000200; // Used with agent dispositions
  const RCS_ACCOUNT_CONTINUE_CALLING = 0x00000400; // Used with agent dispositions

  const RCS_ACCOUNT_PERMANENT = 0x00002000; // Used with do not call/no contact
  const RCS_ACCOUNT_TEST = 0x00008000;

  const RCS_ACCOUNT_TRANS_ANSMACH_OK = 0x00100000;
  const RCS_ACCOUNT_TRANS_NOTIFY = 0x00200000;
  const RCS_ACCOUNT_TRANS_OK = 0x00400000;
  const RCS_ACCOUNT_TRANS_HOLD_OK = 0x00800000;

  const RCS_ACCOUNT_SECONDARY_CALL = 0x01000000;
  const RCS_ACCOUNT_PREVIEW_CALL = 0x02000000;
  const RCS_ACCOUNT_SMS = 0x04000000;
  const RCS_ACCOUNT_EMAIL = 0x08000000;

  const RCS_ACCOUNT_EXTERNAL = 0x40000000;
  const RCS_ACCOUNT_COMPLETED = 0x80000000;

  // Agent Dialer Flags
  const RCS_PAUSE_RECORDING_AGENT = 0x00100000;
  const RCS_KEEP_CURRENT_RECORDING_AGENT = 0x00000001;
  const RCS_KEEP_CURRENT_RECORDING_MNGR = 0x00000002;
  const RCS_KEEP_ALL_RECORDINGS = 0x00000004;
  const RCS_AGENT_RECORDING_ACTIVE = 0x00000008;
  const RCS_AGENT_ANS_MACH_OK = 0x00000010;
  const RCS_AGENT_ANS_MACH_DONE = 0x00000020;
  const RCS_AGENT_TRANSFER_OK = 0x00000040;
  const RCS_AGENT_TRANSFER_DONE = 0x00000080;
  const RCS_AGENT_CALL_REQUEST_WAITING = 0x00002000;
  const RCS_AGENT_CALL_INQUEUE = 0x00004000;
  const RCS_AGENT_CALL_WAITING = 0x00008000;
  const RCS_AGENT_ONCALL = 0x00010000;
  const RCS_AGENT_ONCALL_WRAPUP = 0x00020000;
  const RCS_AGENT_CALL_ONHOLD = 0x00040000;

  function getAccountFlags(account) {
    let accountFlags = account.Variant;
    return {
      Inbound: (accountFlags & RCS_ACCOUNT_INBOUND) !== 0,
      Outbound: (accountFlags & RCS_ACCOUNT_OUTBOUND) !== 0,
      OutboundIC: (accountFlags & RCS_ACCOUNT_IC) !== 0,
      DialRequest: (accountFlags & RCS_ACCOUNT_DIAL_REQUEST) !== 0,
      AutoAccept: (accountFlags & RCS_ACCOUNT_AUTO_ACCEPT) !== 0,
      PreviewAutoAccept: (accountFlags & RCS_ACCOUNT_PREVIEW_AUTO_ACCEPT) !== 0,
      EnableCallProgress: (accountFlags & RCS_ACCOUNT_ENABLE_CALL_PROGRESS) !== 0,
      EnableAnsMachDetect: (accountFlags & RCS_ACCOUNT_ENABLE_ANS_MACH_DETECT) !== 0,
      AgentCall: (accountFlags & RCS_ACCOUNT_AGENT_CALL) !== 0,
      Test: (accountFlags & RCS_ACCOUNT_TEST) !== 0,
      TransferAnsMachOk: (accountFlags & RCS_ACCOUNT_TRANS_ANSMACH_OK) !== 0,
      TransferNotify: (accountFlags & RCS_ACCOUNT_TRANS_NOTIFY) !== 0,
      TransferOk: (accountFlags & RCS_ACCOUNT_TRANS_OK) !== 0,
      TransferHoldOk: (accountFlags & RCS_ACCOUNT_TRANS_HOLD_OK) !== 0,
      SecondaryCall: (accountFlags & RCS_ACCOUNT_SECONDARY_CALL) !== 0,
      PreviewCall: (accountFlags & RCS_ACCOUNT_PREVIEW_CALL) !== 0,
      IsSMS: (accountFlags & RCS_ACCOUNT_SMS) !== 0,
      IsEmail: (accountFlags & RCS_ACCOUNT_EMAIL) !== 0,
      External: (accountFlags & RCS_ACCOUNT_EXTERNAL) !== 0,
      Completed: (accountFlags & RCS_ACCOUNT_COMPLETED) !== 0,
    };
  }

  function getAgentFlags(flags) {
    return {
      AgentPauseRecording: (flags & RCS_PAUSE_RECORDING_AGENT) !== 0,
      KeepCurrentRecordingAgent: (flags & RCS_KEEP_CURRENT_RECORDING_AGENT) !== 0,
      KeepCurrentRecordingMgr: (flags & RCS_KEEP_CURRENT_RECORDING_MNGR) !== 0,
      KeepAllRecordings: (flags & RCS_KEEP_ALL_RECORDINGS) !== 0,
      RecordingActive: (flags & RCS_AGENT_RECORDING_ACTIVE) !== 0,
      AnsMachineOk: (flags & RCS_AGENT_ANS_MACH_OK) !== 0,
      AnsMachineDone: (flags & RCS_AGENT_ANS_MACH_DONE) !== 0,
      TransferOk: (flags & RCS_AGENT_TRANSFER_OK) !== 0,
      TransferDone: (flags & RCS_AGENT_TRANSFER_DONE) !== 0,
      CallRequestWaiting: (flags & RCS_AGENT_CALL_REQUEST_WAITING) !== 0,
      CallInQueue: (flags & RCS_AGENT_CALL_INQUEUE) !== 0,
      CallWaiting: (flags & RCS_AGENT_CALL_WAITING) !== 0,
      OnCall: (flags & RCS_AGENT_ONCALL) !== 0,
      OnCallWrapUp: (flags & RCS_AGENT_ONCALL_WRAPUP) !== 0,
      CallOnHold: (flags & RCS_AGENT_CALL_ONHOLD) !== 0,
    };
  }

  $scope._updateAgentResultList = function (agentResultList, errorFn) {
    $scope._agentResultList = [];

    if (agentResultList && agentResultList.length > 0) {
      agentResultList.forEach((element) => {
        let item = {
          resultCode: element.Result,
          description: element.Desc,
        };
        $scope._agentResultList.push(item);
      });
    }
    store.commit("contact/setSmdDispositions", $scope._agentResultList);
  };

  $scope._connectAgentAudio = function (audioAddress, path) {
    $scope._info(`connectAgentAudio(${audioAddress})`);

    let endpoint = window.connect.Endpoint.byPhoneNumber(audioAddress, $scope._applicationName + "." + $scope._agentID);

    $scope._debug(`endpoint: ${JSON.stringify(endpoint)}`);

    $scope.isConnectingMode = true;
    $scope._connectAgent.connect(endpoint, {
      //queueARN: "",
      success: function () {
        $scope._info("Success!");
        if (path === "preview") {
          store.commit("contact/setSmartDialContact", { status: "connected-preview-call" });
        }
      },
      failure: function () {
        $scope._warning("Failure!");
        store.commit("contact/setSmartDialContact", { status: "init", contactInformation: {}, accountConnected: "init" });
      },
    });
  };

  // HERE: This is not a generic solution... There are few unknowns here that we need to talk about...
  $scope._processPhoneNumber = function (rawPhoneNumber) {
    if (!rawPhoneNumber) return "NA";

    let phoneNumber = rawPhoneNumber;

    // sip:+18885038362@lily-outbound ????
    if (rawPhoneNumber.startsWith("sip:")) {
      phoneNumber = phoneNumber.slice(4);
      phoneNumber = phoneNumber.slice(0, phoneNumber.indexOf("@"));
      phoneNumber =
        phoneNumber.slice(0, 2) + "-" + phoneNumber.slice(2, 5) + "-" + phoneNumber.slice(5, 8) + "-" + phoneNumber.slice(8);
    }

    return phoneNumber;
  };

  //--------------------------------------------------------------------------------
  // Amazon Connect Streams event handlers
  $scope._subscribeToAgentEvents = function (agent) {
    let region, awsAccountID, connectInstanceID;
    $scope._info(`#SSD Subscribing to events for agent ${agent.getName()}`);
    $scope._info(`Agent is currently in status of ${agent.getStatus().name}`);

    //agent.onRefresh($scope._handleAgentRefresh);
    try {
      agent.onRoutable($scope._handleAgentRoutable);
    } catch (error) {
      $scope._error(error);
    }
    agent.onNotRoutable($scope._handleAgentNotRoutable);
    agent.onOffline($scope._handleAgentOffline);
    agent.onAfterCallWork($scope._handleAgentAfterCallWork);

    if ($scope._connectLogging) agent.onContactPending($scope._handleAgentContactPending);
    agent.onStateChange($scope._handleAgentStateChange);

    $scope._connectAgent = agent;
    $scope._agentConfig = agent.getConfiguration();

    if ($scope._agentConfig.routingProfile.routingProfileARN) {
      let match = $scope._agentConfig.routingProfile.routingProfileARN.match(
        /arn:aws:connect:([^:]+):([^:]+):instance\/([^/]+)\//
      );
      if (match !== null) {
        region = match[1];
        awsAccountID = match[2];
        connectInstanceID = match[3];

        $scope._info(`AWS Account ID: ${awsAccountID}`);
        $scope._info(`Region: ${region}`);
        $scope._info(`Connect Instance ID: ${connectInstanceID}`);

        $scope._callCenterQueueName = `SD_${connectInstanceID}.fifo`;
        $scope._resolveCallCenterQueueUrl();
      } else {
        $scope._warning("Unable to extract connect instance and related information from the agent's routing profile!");
      }
    }

    // Save the names of the default states we need to find by type
    for (let i = 0; i < $scope._agentConfig.agentStates.length; i++) {
      let state = $scope._agentConfig.agentStates[i];

      if (state.type === window.connect.AgentStateType.ROUTABLE) $scope._availableStateName = state.name;
      else if (state.type === window.connect.AgentStateType.OFFLINE) $scope._offlineStateName = state.name;
    }

    $scope._info(`Status: ${JSON.stringify(agent.getStatus())}`);

    // Save the "globals"
    $scope._agentID = $scope._agentConfig.username;
    $scope._connectInstanceID = connectInstanceID;
  };

  //$scope._handleAgentRefresh = function (agent) {

  //};

  $scope._handleAgentRoutable = function (agent) {
    $scope._info(`[agent.onRoutable] Agent is routable. Agent status is ${agent.getStatus().name}`);
    $scope.setInfoMessage(`Agent is routable. Agent's status is ${agent.getStatus().name}`);

    // Routable in Amazon Connect, so clear this data (we don't have a contact any more)
    $scope._contactData.contact = null;
    $scope._contactData.isAborted = false;

    if ($scope._isConnected) {
      $scope._sendQueueMessage($scope._callCenterQueueUrl, "Ready", $scope._agentID);
    } else {
      $scope._info("Not connected to the CallCenter interface.");

      $scope._sendConnect();
    }
  };

  $scope._handleAgentNotRoutable = function (agent) {
    $scope._info(`[agent.onNotRoutable] Agent is online, but not routable. Agent status is ${agent.getStatus().name}`);

    if ($scope._isConnected) {
      var agentState = agent.getStatus();

      if (agentState.name !== $scope._previewStateName && agentState.name !== $scope._busyStateName)
        $scope._sendQueueMessage($scope._callCenterQueueUrl, "Busy", $scope._agentID);
    } else {
      $scope._warning("Not connected to the CallCenter interface.");

      $scope._sendConnect();
    }
  };

  $scope._handleAgentOffline = function (agent) {
    $scope._info(`[agent.onOffline] Agent is offline. Agent status is ${agent.getStatus().name}`);
    $scope.setInfoMessage("");
    if ($scope._isConnected || $scope._isConnecting) {
      $scope._sendQueueMessage($scope._callCenterQueueUrl, "Disconnect", $scope._agentID);

      $scope._agentQueueName = null;
      $scope._agentQueueUrl = null;
    }

    $scope._isConnected = $scope._isConnecting = false;
  };

  $scope._handleAgentAfterCallWork = function (agent) {
    $scope._info(`[agent.onAfterCallWork] Agent is wrapping up. Agent status is ${agent.getStatus().name}`);

    if ($scope.isConnectingMode) {
      $scope._info(`[contact.onAfterCallWork] isConnectingMode==${$scope.isConnectingMode}`);

      $scope._goToState($scope._availableStateName);
      $scope.isConnectingMode = false;
    }
  };

  $scope._handleAgentContactPending = function (agent) {
    $scope._info(`[agent.onContactPending] Agent has a contact pending. Agent status is ${agent.getStatus().name}`);
  };

  $scope._handleAgentStateChange = function (arg) {
    let oldState = arg.oldState;
    let newState = arg.newState;
    let isAborted = $scope._contactData && $scope._contactData.isAborted;

    // Check for error conditions first ("FailedConnectAgent" or "FailedConnectCustomer")
    if (
      (newState === window.connect.AgentErrorStates.FAILED_CONNECT_AGENT ||
        newState === window.connect.AgentErrorStates.FAILED_CONNECT_CUSTOMER) &&
      $scope._managePreviewAccepted
    ) {
      //TODO: On a preview call, the agent will hear early media and will be provided a UI to select from a list of dial results.
      $scope._info(`[agent.onStateChange] oldState == ${oldState}; newState == ${newState}; arg = ${JSON.stringify(arg)}`);

      if (!$scope._contactData.previewAbortCode) {
        if ($scope._onCallPreviewFailedCallback) $scope._onCallPreviewFailedCallback();
        else {
          if (newState === window.connect.AgentErrorStates.FAILED_CONNECT_AGENT) this.previewDialResultDialFailed();
          else this.previewDialResultNoAnswer();
        }
      }
      $scope._goToState($scope._previewStateName);
    } else if (
      newState === $scope._offlineStateName ||
      (newState === window.connect.AgentErrorStates.FAILED_CONNECT_AGENT && !isAborted)
    ) {
      $scope.isConnectingMode = false;
    } else if (oldState === "Init" && newState === $scope._previewStateName) {
      $scope._debug(`[agent.onStateChange] Agent in invalid state! ${arg.oldState} => ${arg.newState}`);
      $scope._goToState($scope._offlineStateName);
      return;
    } else if (newState === $scope._previewStateName) {
      //$scope.isConnectingMode = false;

      if ($scope._contactData.previewAbortCode) {
        $scope._info(
          `[agent.onStateChange] Agent state changed with previewAbortCode (${$scope._contactData.previewAbortCode}): ${arg.oldState} => ${arg.newState}`
        );

        if (!$scope._managePreviewAudio) $scope._managePreviewAccepted = false;
      }
    } else if (oldState === $scope._previewStateName) {
      // In ManagePreviewAudio mode, don't turn off the Preview yet (we're doing the dialing)
      if (!$scope._managePreviewAudio) {
        // Placeholder
      }

      //if (newState === "CallingCustomer") {
      //    //$scope.isConnectingMode = true;
      //}
      ////else
      ////    $scope.isConnectingMode = false;
    } else if (newState === $scope._availableStateName) {
      $scope.isConnectingMode = false;
    }
    //else if (oldState === $scope._availableStateName) {
    //    if (newState === "CallingCustomer")
    //        $scope.isConnectingMode = true;
    //    else
    //        $scope.isConnectingMode = false;
    //}
    else if (newState === $scope._busyStateName) {
      //** *TODO: Remove: This is not used
    } else {
      $scope._debug(`[agent.onStateChange] No special processing for state change from ${arg.oldState} to ${arg.newState}`);
    }

    $scope._debug(`[agent.onStateChange] Is aborted? ${isAborted}`);
    $scope._info(`[agent.onStateChange] Agent state has changed from ${arg.oldState} to ${arg.newState}`);
  };

  $scope._subscribeToContactEvents = function (contact) {
    $scope._contactData.contact = contact;
    $scope._contactData.isAborted = false;

    $scope._debug(` => Contact id: ${$scope._contactData.contact.getContactId()}`);
    $scope._info("Subscribing to events for contact");

    if (contact.getActiveInitialConnection() && contact.getActiveInitialConnection().getEndpoint()) {
      // HERE: We have a major problem to deal with here ... we potentially don't know at this stage the name of the caller...
      // And the phone number doesn't map yet to a SmartDial user ...
      let endpoint = contact.getActiveInitialConnection().getEndpoint();
      let phoneNumber = $scope._processPhoneNumber(endpoint.phoneNumber);
      let name = endpoint.name || "NA";

      $scope._info(`New contact is to/from ${phoneNumber}`);
    } else $scope._info("This is an existing contact for this agent");

    if ($scope._connectLogging) {
      $scope._info(`Contact state is ${contact.getStatus().type}`);
      $scope._info(`Contact is from queue ${contact.getQueue().name}`);
      $scope._info(`->isInbound(): ${contact.isInbound() ? "Yes" : "No"}`);

      contact.onIncoming($scope._handleContactIncoming);
      contact.onAccepted($scope._handleContactAccepted);
      contact.onRefresh($scope._handleContactRefresh);
    }
    contact.onConnected($scope._handleContactConnected);
    contact.onEnded($scope._handleContactEnded);
    contact.onDestroy($scope._handleContactDestroy);

    let params = {
      Data: { DataType: "String", StringValue: "1" },
      CallKey: { DataType: "String", StringValue: contact.getContactId() },
      Direction: { DataType: "String", StringValue: "" },
    };

    if (contact.isInbound()) {
      $scope.setInfoMessage(`Inbound call from ${contact.getActiveInitialConnection().getEndpoint().phoneNumber}`);
      params.Direction.StringValue = "Inbound";
    } else params.Direction.StringValue = "Outbound";

    if (!$scope._managePreviewAccepted && !$scope._audioPIN) {
      $scope._sendQueueMessage($scope._callCenterQueueUrl, "OnCall", $scope._agentID, params);
      $scope._contactData.sendWrapUp = true;
    }
  };

  $scope._handleContactIncoming = function (contact) {
    if (contact) {
      $scope._info(`[contact.onIncoming] Contact is incoming. Contact state is ${contact.getStatus().type}`);
      $scope._info(`->isInbound(): ${contact.isInbound() ? "Yes" : "No"}`);
    } else $scope._info("[contact.onIncoming] Contact is incoming. Null contact passed to event handler");
  };

  $scope._handleContactAccepted = function (contact) {
    if (contact) $scope._info(`[contact.onAccepted] Contact accepted by agent. Contact state is ${contact.getStatus().type}`);
    else $scope._info("[contact.onAccepted] Contact accepted by agent. Null contact passed to event handler");
  };

  $scope._handleContactConnected = function (contact) {
    if (!contact) {
      $scope._info("[contact.onConnected] Contact connected to agent. Null contact passed to event handler");
      return;
    }

    $scope._info(`[contact.onConnected] Contact connected to agent. Contact state is ${contact.getStatus().type}`);

    if ($scope._managePreviewAccepted) {
      $scope._info("[contact.onConnected] Managed Preview Audio mode.  Sending OnCall with 'Connected' result.");

      let params = {
        Data: { DataType: "String", StringValue: "1" },
        CallKey: { DataType: "String", StringValue: contact.getContactId() },
        Direction: { DataType: "String", StringValue: "Outbound" },
      };

      $scope._sendQueueMessage($scope._callCenterQueueUrl, "OnCall", $scope._agentID, params);
      $scope._contactData.sendWrapUp = true;

      $scope._managePreviewAccepted = false;
    } else if ($scope._audioPIN) {
      if (!$scope._onAudioNeededCallback) {
        // We're handling the audio, so send the digits
        contact.getActiveInitialConnection().sendDigits($scope._audioPIN + "#", {
          success: function () {
            $scope._info("Successfully sent the PIN digits!");
          },
          failure: function () {
            $scope._info("Failed to send the PIN digits!");
          },
        });
      }

      $scope._audioPIN = "";
    }
  };

  $scope._handleContactRefresh = function (contact) {
    if (contact)
      $scope._info(
        `[contact.onRefresh] Contact refreshed. Contact state is ${contact.getStatus().type}\r\ncontact: ${JSON.stringify(
          contact
        )}`
      );
    else $scope._info("[contact.onRefresh] Contact refreshed. Null contact passed to event handler");
  };

  $scope.setInfoMessage = function (message) {
    store.commit("contact/setSMDStatus", message);
  };

  // IMPORTANT:
  $scope._handleContactEnded = function (contact) {
    if (!contact) {
      $scope._info("[contact.onEnded] Contact has ended. Null contact passed to event handler");
      return;
    }

    $scope._info(`[contact.onEnded] Contact has ended. Contact state is ${contact.getStatus().type}`);

    if ($scope._contactData.sendWrapUp) {
      let params = {
        // Note that the CallKey and Direction fields were sent previously (when the contact was connected)
        Data: { DataType: "String", StringValue: "1" },
      };
      $scope._sendQueueMessage($scope._callCenterQueueUrl, "OnCallWrapUp", $scope._agentID, params);

      // OnCallWrapUp has been sent to the middleware, so we can clear this flag
      $scope._contactData.sendWrapUp = false;
    }
  };

  $scope._handleContactDestroy = function (contact) {
    if (!contact) {
      $scope._info("[contact.onDestroy] Contact has been destroyed. Null contact passed to event handler");
      return;
    }

    $scope._info(`[contact.onDestroy] Contact has been destroyed. Contact state is ${contact.getStatus().type}`);

    // In case this was an audio call that never got connected, clear the audio PIN
    $scope._audioPIN = "";

    // Make sure the middleware knows the agent's state
    let agentState = $scope._connectAgent.getStatus();
    $scope._info(`[contact.onDestroy] Agent state is ${agentState.name} (type=${agentState.type})`);

    if (agentState.type === window.connect.AgentStateType.ROUTABLE)
      $scope._sendQueueMessage($scope._callCenterQueueUrl, "Ready", $scope._agentID);
    else if (agentState.name === $scope._previewStateName) $scope._goToState($scope.previewStateName);
    else if (agentState.name !== $scope._busyStateName)
      $scope._sendQueueMessage($scope._callCenterQueueUrl, "Busy", $scope._agentID);
  };

  $scope._goToState = function (stateName) {
    var agentStates = $scope._connectAgent.getAgentStates();
    var state;

    for (let i = 0; i < agentStates.length; i++) {
      if (agentStates[i].name === stateName) {
        state = agentStates[i];
        break;
      }
    }
    if (state) {
      $scope._connectAgent.setState(state, {
        success: function () {
          $scope._info(`Set agent status to ${state.name} via Streams`);
        },
        failure: function (err) {
          $scope._info(`Failed to set agent status to ${state.name} via Streams:\r\n${err.type}: ${err.message}`);
        },
      });
    } else $scope._error(`Attempt to go to an undefined state of ${stateName}!`);
  };

  $scope._hangUpAgentAudio = function () {
    let contact = $scope._contactData.contact;
    if (contact) {
      let agentConn = contact.getAgentConnection();
      if (agentConn) {
        let contactStatus = contact.getStatus();
        if (
          contactStatus.type === window.connect.ContactStateType.CONNECTING ||
          contactStatus.type === window.connect.ContactStateType.CONNECTED
        ) {
          agentConn.destroy({
            success: function () {
              $scope._info(` => Disconnected contact ${$scope._contactData.contact.getContactId()}`);

              $scope._contactData.isAborted = true;
              $scope._contactData.contact = null;
            },
            failure: function () {
              $scope._info(` => Failed to disconnect contact ${$scope._contactData.contact.getContactId()}`);

              $scope._contactData.isAborted = false;
              $scope._contactData.contact = null;
            },
          });
        }
      }
    } else $scope._info("_hangUpAgentAudio(): No contact to hang up");
  };

  SmartDial.prototype.$scope = $scope;

  SmartDial.prototype.init = function (config, agent) {
    if (!config) {
      $scope._error("The SmartDial configuration object was not provided.");
      return;
    }

    if (config.region) {
      window.sMSDK.config.region = config.region;
    }
    if (config.identityPoolId) {
      window.sMSDK.config.credentials = new window.sMSDK.CognitoIdentityCredentials({
        IdentityPoolId: config.identityPoolId,
      });
      window.sMSDK.config.getCredentials(function (err) {
        if (err) {
          $scope._error(err);
          $scope._throwError("CredentialsError: Could not load credentials from CognitoIdentityCredentials");
          return;
        } else {
          $scope._info("Successfully authenticated..!");
        }
      });
    } else {
      $scope._info(
        "An identity pool ID was not provided; assuming the caller has configured the identity pool for the Amazon SDK."
      );
    }

    if (config.busyStateName) $scope._busyStateName = config.busyStateName;
    if (config.previewStateName) $scope._previewStateName = config.previewStateName;
    if (window.connect.core?.initialized && !$scope.isInitialized) {
      $scope._info("SSD : Initializing dialer functions...");

      // AWS initialization

      $scope.sqs = new window.sMSDK.SQS({ region: config.region });

      // Amazon Connect initialization
      window.connect.agent($scope._subscribeToAgentEvents);
      window.connect.contact($scope._subscribeToContactEvents);

      // Local initialization (the config object has already been validated as being there)
      $scope._onConnectedCallback = config.onConnected;
      $scope._onDisconnectedCallback = config.onDisconnected;
      $scope._onUserNotificationCallback = config.onUserNotification;
      $scope._onCallCallback = config.onCall;
      $scope._onPreviewCallback = config.onPreview;
      $scope._onAudioNeededCallback = config.onAudioNeeded;
      $scope._onAudioNotNeededCallback = config.onAudioNotNeeded;
      $scope._onStateChangedCallback = config.onStateChanged;
      $scope._onListTransitionCallback = config.onListTransition;
      $scope._onPreviewAcceptedCallback = config.onPreviewAccepted;
      $scope._onCallPreviewCallback = config.onCallPreview;
      $scope._onCallPreviewFailedCallback = config.onCallPreviewFailed;
      $scope._onFlagsChangedCallback = config.onFlagsChanged;
      $scope._onAllowTransferAnsweringMachineCallback = config.onAllowTransferAnsweringMachine;
      $scope._onActivateCallBack = config.onActivate;
      $scope._throwError = config.onError;

      // Initialization complete
      $scope.isInitialized = true;
      $scope._info("Initialization completed.");

      // Refresh the page when we receive the TERMINATED response from the shared worker.
      window.connect.core.getEventBus().subscribe(window.connect.EventType.TERMINATED, function () {
        // Notify the middleware that the user has gone away
        if ($scope._isConnected || $scope._isConnecting) {
          $scope._sendQueueMessage($scope._callCenterQueueUrl, "Disconnect", $scope._agentID);

          if ($scope._onDisconnectedCallback) $scope._onDisconnectedCallback();
        }
      });
    } else {
      $scope._subscribeToAgentEvents(agent);
    }
  };

  SmartDial.prototype.getVersion = function () {
    return sdVersion;
  };

  SmartDial.prototype.getRevision = function () {
    return sdRevision;
  };

  SmartDial.prototype.acceptPreview = function (accept) {
    if (!window.connect || !$scope.isInitialized) return;

    if (accept) {
      $scope._sendQueueMessage($scope._callCenterQueueUrl, "AcceptAccount", $scope._agentID, {});
      store.commit("contact/setSmartDialContact", {
        status: "connecting-preview-call",
      });
      if ($scope._onPreviewAcceptedCallback) $scope._onPreviewAcceptedCallback();

      if ($scope._managePreviewAudio) {
        // Dial Contact
        $scope._managePreviewAccepted = true;

        $scope._info("Calling preview contact.");
        if ($scope._onCallPreviewCallback) {
          let arg = {
            audioAddress: $scope._previewInfoNumber,
          };

          $scope._onCallPreviewCallback(arg);
        } else $scope._connectAgentAudio($scope._previewInfoNumber, "preview");
      }
    } else {
      $scope._sendQueueMessage($scope._callCenterQueueUrl, "RejectAccount", $scope._agentID, {});
      $scope._goToState($scope._availableStateName);

      if ($scope._managePreviewAudio) {
        // Placeholder
      }
    }
  };

  SmartDial.prototype.dispositionAccount = function (result) {
    // If a result wasn't provided, don't bother
    if (!result || result.Result === undefined) return;

    // Build the parameters object
    let resultParams = {};

    if (result.Result) {
      if (typeof result.Result === "number")
        resultParams["Result"] = { DataType: "String", StringValue: result.Result.toString() };
      else if (typeof result.Result === "string") resultParams["Result"] = { DataType: "String", StringValue: result.Result };
    }

    if (result.ResultValue) resultParams["ResultValue"] = { DataType: "String", StringValue: result.ResultValue };

    let agentAction = 0;

    if (result.Continue) agentAction |= RCS_ACCOUNT_CONTINUE_CALLING;

    if (result.CallBack) {
      agentAction |= RCS_ACCOUNT_CALL_NUMBER;

      if (result.CallBackNumber) resultParams["CallBackNumber"] = { DataType: "String", StringValue: result.CallBackNumber };

      if (result.Ownership) agentAction |= RCS_ACCOUNT_AGENT_CALL;

      resultParams["Delay"] = { DataType: "String", StringValue: "0" };

      if (Object.prototype.hasOwnProperty.call(result, "CallBackDelay")) {
        if (typeof result.CallBackDelay === "number") resultParams.Delay.StringValue = result.CallBackDelay.toString();
        else if (typeof result.CallBackDelay === "string") {
          if (result.CallBackDelay.match(/^\d+$/)) resultParams.Delay.StringValue = result.CallBackDelay;
        }
      }
    }

    resultParams["AgentAction"] = { DataType: "String", StringValue: agentAction.toString() };

    $scope._sendQueueMessage($scope._callCenterQueueUrl, "Result", $scope._agentID, resultParams);
  };

  SmartDial.prototype.transferAnsweringMachine = function (account) {
    $scope._sendQueueMessage($scope._callCenterQueueUrl, "TransferAnsweringMachine", $scope._agentID, null);
  };

  SmartDial.prototype.previewDialResult = function (result) {
    if ($scope._managePreviewAccepted) {
      $scope._info(`previewDialResult: ${result}`);
      if (result) {
        $scope._contactData.previewAbortCode = result;

        let params = {
          Data: { DataType: "String", StringValue: $scope._contactData.previewAbortCode },
          CallKey: { DataType: "String", StringValue: $scope._contactData.contact?.getContactId() },
          Direction: { DataType: "String", StringValue: "Outbound" },
        };
        $scope._sendQueueMessage($scope._callCenterQueueUrl, "OnCallWrapUp", $scope._agentID, params);

        if ($scope._managePreviewAudio) {
          // If the client isn't handling the audio connection for Manage Preview Audio
          if (!$scope._onCallPreviewCallback) $scope._hangUpAgentAudio(); // Hang up the agent's audio
        }
      }
    } else
      $scope._warning(
        "Invalid State: previewDialResult can only be set after accepting a preview and before the preview contact is connected"
      );
  };

  SmartDial.prototype.previewDialResultBusy = function () {
    this.previewDialResult("BUSY");
  };

  SmartDial.prototype.previewDialResultNoAnswer = function () {
    this.previewDialResult("NO_ANSWER");
  };

  SmartDial.prototype.previewDialResultSIT = function () {
    this.previewDialResult("OPER_SIT");
  };

  SmartDial.prototype.previewDialResultDialFailed = function () {
    this.previewDialResult("UNEXPECTD_CAL_RESULT");
  };

  SmartDial.prototype.getAgentDispositions = function () {
    let list = [];
    $scope._agentResultList.forEach((element) => {
      let item = {
        resultCode: element.resultCode,
        description: element.description,
      };
      list.push(item);
    });

    return list;
  };

  SmartDial.prototype.addNoContact = function (accountID, isPermanent, reasonNote) {
    if (accountID) {
      let params = {
        ID: { DataType: "String", StringValue: accountID },
        IsPermanent: { DataType: "String", StringValue: isPermanent ? "true" : "false" },
        Note: { DataType: "String", StringValue: reasonNote },
      };

      $scope._sendQueueMessage($scope._callCenterQueueUrl, "AccountRemove", $scope._agentID, params);
    }
  };

  SmartDial.prototype.addDoNotCall = function (phoneNumber, isPermanent, reasonNote) {
    if (phoneNumber) {
      let params = {
        Phone: { DataType: "String", StringValue: phoneNumber },
        IsPermanent: { DataType: "String", StringValue: isPermanent ? "1" : "0" },
        Note: { DataType: "String", StringValue: reasonNote },
      };

      $scope._sendQueueMessage($scope._callCenterQueueUrl, "AccountRemove", $scope._agentID, params);
    }
  };

  SmartDial.prototype.getAgentProperty = function (propertyName) {
    let searchName = propertyName.toUpperCase();
    let prop = $scope._agentProperties.find((element) => element.propertyName === searchName);
    return prop ? prop.value : null;
  };

  SmartDial.prototype.subscribeAgentEvents = function (agent) {
    $scope._subscribeToAgentEvents(agent);
  };

  const falseStrings = ["FALSE", "F", "NO", "N", "0"];
  SmartDial.prototype.getAgentPropertyAsBool = function (propertyName) {
    let propValue = this.getAgentProperty(propertyName);
    if (propValue) {
      let upperValue = propValue.toUpperCase();
      let match = falseStrings.find((element) => element === upperValue);
      if (match) return false;

      return true;
    } else return false;
  };

  SmartDial.prototype.getVariantFlags = function (variant) {
    return getAccountFlags({ variant: variant });
  };

  return new SmartDial();
})();
