import Vue from "vue";

let socket;
const queue = [];

import { PeerCallStatus } from "./constant";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import store from "@/store";
import { Amplify } from "@aws-amplify/core";
import { DateTime } from "luxon";
import { Auth } from "aws-amplify";
import axios from "axios";
import { getApiRestEndpoint, getWebSocketEndPoint } from "@/utils/configs";
import AmplifyHandler from "@/plugins/amplify";
const sign = require("jwt-encode");

let amplify = new AmplifyHandler();
const stateEnum = {
  0: "CONNECTING",
  1: "OPEN",
  2: "CLOSING",
  3: "CLOSED",
  4: "UN-DEFINED",
};
const emitter = new Vue({
  store,
  data() {
    return {
      specialToken: {
        encodedToken: "",
      },
      initiator: {},
      monitorInit: {},
      socketHeartBeatInterval: null,
      minutePassedCounter: 0,
      socketConnectionStatus: stateEnum["4"],
    };
  },
  computed: {
    ...mapGetters("team", ["getCallStatus"]),
    currentUser() {
      return this.$store.state?.api?.api?.getUser ?? {};
    },
    agentSummary() {
      return this.$store.state?.agent?.agent?.summary ?? {};
    },
    timeRemainingForTokenExpiry() {
      let session = this.currentUser.session;
      const { idToken } = session;
      this.setToken(idToken);
      return DateTime.fromMillis(idToken?.getExpiration() * 1000).diffNow("minutes").minutes;
    },
  },
  methods: {
    ...mapMutations("team", ["setCoturnCredentials"]),
    ...mapActions("logs", ["addItem", "addSocketLog"]),
    connect() {
      initSocket();
    },
    send(message) {
      let event = JSON.parse(message);
      let payload = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
        text: `[LAMBDA_SOCKET_CLIENT] [${this.agentSummary?.Username}] [${event.action}] : Sending request to socket server.`,
        messageContext: event,
      };

      console.log("#SC - ", socket?.readyState, WebSocket.OPEN, WebSocket.CONNECTING, this.currentUser, payload);

      if (socket?.readyState === WebSocket.CLOSED) {
        queue.push(message);
        socket = null;
        this.open();
      } else if (socket?.readyState === WebSocket.OPEN) {
        console.log("#SC - Sending Message :", event.action, payload);
        try {
          socket.send(message);
          this.addSocketLog(payload);
        } catch (error) {
          console.error("#SC - error in sending message : ", error);
          payload.level = "ERROR";
          payload.text = `[LAMBDA_SOCKET_CLIENT] [${this.agentSummary?.Username}] [${event.action}] : Error while sending request to server`;
          payload.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
          this.addSocketLog(payload);
        }
      } else if (socket?.readyState === WebSocket.CONNECTING) {
        console.log("#SC - Queueing Message :", event, event.action);
        queue.push(message);
      }
    },
    close() {
      clearInterval(this.socketHeartBeatInterval);
      this.minutePassedCounter = 0;
      this.socketHeartBeatInterval = null;
      let payload = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
        text: "",
        messageContext: {
          BASE_URL: getWebSocketEndPoint(this.agentSummary?.Region),
          TOKEN: this.specialToken.encodedToken,
          InstanceId: this.specialToken.payload["custom:InstanceId"],
        },
      };
      try {
        payload.text = `[LAMBDA_SOCKET_CLIENT] [${this.agentSummary?.Username}] [DISCONNECT] : Sending Disconnect Request to socket server.`;
        this.addSocketLog(payload);
        socket.close();
      } catch (error) {
        payload.text = `[LAMBDA_SOCKET_CLIENT] [${this.agentSummary?.Username}] [DISCONNECT] : Error while trying to disconnect from socket server.`;
        payload.level = "ERROR";
        payload.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
        this.addSocketLog(payload);
        console.error("#SC - error on closing socket :", error);
      }
    },
    open() {
      initSocket();
    },
    decode(token) {
      const base64Url = token.jwtToken.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    isReady() {
      return socket.readyState === WebSocket.OPEN;
    },
    setToken(token) {
      this.specialToken = { ...token };
      const decoded = this.decode(token);
      const reqInf = {
        instanceId: token.payload["custom:InstanceId"],
        _instanceAlias: decoded._instanceAlias,
        "custom:Username": decoded["custom:Username"],
        _sessionToken: decoded._sessionToken,
        _region: decoded._region,
        _secretAccessKey: decoded._secretAccessKey,
        _clientOwnedResources: decoded._clientOwnedResources,
        _authToken: decoded._authToken,
        _accessKeyId: decoded._accessKeyId,
        _customerIdentifier: decoded._customerIdentifier,
        exp: decoded.exp * 1000,
        iat: decoded.iat,
      };
      this.specialToken.encodedToken = sign(reqInf, reqInf._secretAccessKey);
    },

    getCoturnCredentials() {
      const requestCoturnCredentials = {
        action: "getCoturnCredentials",
        token: this.specialToken.encodedToken,
        instanceId: this.specialToken.payload["custom:InstanceId"],
      };

      this.send(JSON.stringify(requestCoturnCredentials));
    },

    setInitiatorData(payload) {
      this.initiator = {};
      this.initiator = payload;
      this.initiator.token = this.specialToken.encodedToken;
    },

    setMonitorInitiatorData(payload) {
      this.monitorInit = {};
      this.monitorInit = payload;
      this.monitorInit.token = this.specialToken.encodedToken;
    },

    async reConfigureToken() {
      let session = await Amplify.Auth.currentSession();
      const { idToken } = session;
      if (idToken) {
        this.setToken(idToken);
      }
    },

    async updateUserSessionInfo() {
      let currentSession;
      let user = this.currentUser;

      try {
        let session = await axios({
          method: "post",
          url: getApiRestEndpoint(this.agentSummary.Region) + "/guest/login",
          data: user,
        });

        await amplify.configure(this.agentSummary.Region);
        await Amplify.Auth.signIn(session.data.creds.CognitoUser, session.data.creds.CognitoPassword); //Signing In again to get new token.
        currentSession = await Auth.currentSession();
        this.$store.commit("updateCurrentUser", { ...user, session: currentSession });
      } catch (err) {
        console.error("#SC - currentSession - Error : ", err);
      }
    },
    checkInternetConnection(event) {
      if (event.type === "online") {
        if (socket) {
          socket.close();
          socket = null;
        }
        initSocket();
      } else if (event.type === "change") {
        if (socket?.readyState === WebSocket.CLOSED || socket?.readyState === WebSocket.CLOSING) {
          socket.close();
          socket = null;
          initSocket();
        }
      }
    },
  },
  created() {
    window.addEventListener("online", (event) => this.checkInternetConnection(event));
    window.addEventListener("offline", (event) => this.checkInternetConnection(event));
    const conn = navigator?.connection;
    if (conn) {
      conn.addEventListener("change", (event) => {
        this.checkInternetConnection(event);
      });
    }
  },
  destroyed() {
    window.removeEventListener("online");
    window.removeEventListener("offline");
    const conn = navigator?.connection;
    if (conn) {
      conn.removeEventListener("change");
    }
  },
});

const initSocket = () => {
  if (!socket) {
    console.log("#SC - Checking Socket : ", socket);
    const payload = {
      component: "LAMBDA_SOCKET",
      level: "INFO",
      text: "",
      messageContext: {
        BASE_URL: getWebSocketEndPoint(store.state?.agent?.agent?.Region),
        TOKEN: emitter.specialToken.encodedToken,
        InstanceId: emitter.specialToken.payload["custom:InstanceId"],
      },
    };

    try {
      payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [CONNECT] : Sending Connect Request to socket server.`;
      emitter.addSocketLog(payload);
      socket = new WebSocket(
        `${getWebSocketEndPoint(store.state?.agent?.agent?.Region)}?token=qw${emitter.specialToken.encodedToken}&instanceId=${
          emitter.specialToken.payload["custom:InstanceId"]
        }`
      );
      emitter.socketConnectionStatus = stateEnum[socket?.readyState || "4"];
    } catch (error) {
      payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [CONNECT] : Error while trying to connect to socket server.`;
      payload.level = "ERROR";
      payload.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
      emitter.addSocketLog(payload);

      console.error("#SC - error in initializing socket : ", payload);
    }

    emitter.getCoturnCredentials();
    socket.onmessage = (msg) => {
      console.log("#SC - Message Recieved : ", msg);
      const response = JSON.parse(msg.data);

      if (!response.data.error) {
        emitter.addSocketLog({
          level: "INFO",
          text: `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [${response.action}] : Message Recieved from server : ${response.action}`,
          messageContext: response.data,
        });
      } else {
        emitter.addSocketLog({
          level: "ERROR",
          text: `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [${response.action}] : Error Recieved from server while processing : ${response.action}`,
          messageContext: response.data,
        });
      }

      switch (response.action) {
        case "validateConnection":
          console.log("#SC - Socket Connect Status : ", response.data);
          break;

        case "getCoturnCredentials":
          console.log("#SC - Cotrun Status : ", response.data);
          store.commit("team/setCoturnCredentials", response.data);
          break;

        case "fetchUUID":
          if (response.data.error) {
            Vue.prototype.$Notice.error({
              title: response.data.message,
            });
            store.commit("team/setCallStatus", PeerCallStatus.InitialState);
          }
          if (response.data.type === "voiceCall" && !response.data.error) {
            emitter.initiator.uuid = response.data.uuid;
            store.dispatch("team/setupVoiceCall", emitter.initiator);
            store.dispatch("team/startCallTimer", {
              InstanceId: emitter.initiator.InstanceId,
            });
          } else if (response.data.type === "monitor" && !response.data.error) {
            emitter.monitorInit.uuid = response.data.uuid;
            store.dispatch("team/fireInitiator", emitter.monitorInit, { root: 1 });
          }
          break;

        case `${store?.getters["currentUser/getProfileData"]?.Username}-offeredVoiceCall`:
          console.log("#SC - Profile Data : ", store?.getters["currentUser/getProfileData"], response.data);
          store.dispatch("currentUser/onOfferedVoiceCall", response.data);
          break;

        case "setupVoiceCall":
          if (response.data.error) {
            Vue.prototype.$Notice.error({
              title: response.data.message,
            });
            emitter.addItem({
              component: "LAMBDA_SOCKET",
              level: "ERROR",
              text: `Action: Setup Voice Call, Error: ${response.data.message}`,
            });
            store.commit("team/setCallStatus", PeerCallStatus.InitialState);
            store.dispatch("team/destroyVoiceCall");
          }
          break;

        case "heartBeat":
          console.log("#SC - heart-beat :  ", response.data);
          break;

        case "createMonitor":
          if (response.data.error) {
            Vue.prototype.$Notice.error({
              title: response.data.message,
            });
            emitter.addItem({
              component: "LAMBDA_SOCKET",
              level: "ERROR",
              text: `Action: Create Monitor, Error: ${response.data.message}`,
            });
            store.dispatch("team/deleteMonitorInitiator");
          }
          break;

        case `${store?.getters["currentUser/getProfileData"]?.Username}-destroyedMonitor`:
          if (window.mPeers) {
            window.mPeers.destroy();
            window.mPeers = null;
          } else {
            store.dispatch("team/deleteMonitorInitiator");
          }
          break;

        case `${store?.getters["currentUser/getProfileData"]?.Username}-answeredVoiceCall`:
          store.dispatch("currentUser/onAnsweredVoiceCall", response.data);
          break;

        case `${store?.getters["currentUser/getProfileData"]?.Username}-destroyedVoiceCall`:
          store.dispatch("currentUser/onDestroyedVoiceCall", response.data);
          break;

        case `${store?.getters["currentUser/getProfileData"]?.Username}-offeredMonitor`:
          store.dispatch("currentUser/onOfferedMonitor", response.data);
          break;

        case `${store?.getters["currentUser/getProfileData"]?.Username}-AnsweredMonitor`:
          store.dispatch("currentUser/onAnsweredMonitor", response.data);
          break;

        default:
          console.log("#SC - Default Case Message : ", response.action, response.data);
          emitter.$emit(response.action, response.data);
          break;
      }
    };

    socket.onerror = (event) => {
      console.log("#HAR : Socket Error : ", event);
      const payload = {
        component: "LAMBDA_SOCKET",
        level: "ERROR",
        text: `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [SOCKET_NATIVE_ERROR] : Some occured in the socket lib. Open to see details.`,
        messageContext: {
          event: event,
        },
      };
      payload.messageContext.error = JSON.stringify(event, ["isTrusted", "wasClean", "type", "code", "reason"]);
      console.error("#SC - Socket Error : ", event);
      emitter.addSocketLog(payload);
      emitter.socketConnectionStatus = stateEnum[socket?.readyState || "4"];
      // emitter.$emit("error", err);
    };

    socket.onopen = () => {
      console.log("#SC - i am open", queue, stateEnum[socket?.readyState]);
      onSocketOpen();
      const payload = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
      };
      emitter.socketConnectionStatus = stateEnum[socket.readyState || "4"];
      emitter.socketHeartBeatInterval = setInterval(() => {
        if (emitter.minutePassedCounter > 4 && emitter.minutePassedCounter % 5 === 0) {
          let data = {
            action: "heartBeat",
            token: emitter.specialToken.encodedToken,
            instanceId: emitter.specialToken.payload["custom:InstanceId"],
            status: emitter.getCallStatus || PeerCallStatus.InitialState,
          };
          emitter.send(JSON.stringify(data));
        }

        console.log("#SC - Checking Token Expiry: ", emitter.timeRemainingForTokenExpiry);
        if (emitter.timeRemainingForTokenExpiry <= 10) {
          payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [UPDATE_TOKEN] : Updating token.`;
          payload.messageContext = { oldToken: emitter.specialToken.encodedToken };
          emitter.addSocketLog(payload);
          emitter.updateUserSessionInfo();
          emitter.reConfigureToken();
          payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [UPDATE_TOKEN] : Token updated.`;
          payload.messageContext = { newToken: emitter.specialToken.encodedToken };
          emitter.addSocketLog(payload);
        }

        if (socket?.readyState === WebSocket.CLOSED || socket?.readyState === WebSocket.CLOSING) {
          clearInterval(emitter.socketHeartBeatInterval);
          emitter.open();
        }

        emitter.minutePassedCounter = emitter.minutePassedCounter + 1;
        if (emitter.minutePassedCounter >= 60 && store.state.team.callStatus === "InitialState") {
          console.log("#SC - Reconnecting");
          payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [REFRESH_CONNECTION] : Refreshing the connection.`;
          payload.messageContext = { socketState: stateEnum[socket?.readyState || 4] };
          emitter.addSocketLog(payload);
          emitter.close();
          socket = null;
          emitter.open();
          emitter.minutePassedCounter = 0;
        }
      }, 60000);
    };

    socket.onclose = (event) => {
      console.log("#HAR - Socket was closed : ", event);
      const payload = {
        component: "LAMBDA_SOCKET",
        level: "ERROR",
        text: `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [SOCKET_NATIVE_CLOSE] : Socket connection closed due to some error. Open to see details.`,
        messageContext: {
          event: event,
        },
      };
      payload.messageContext.error = JSON.stringify(event, ["isTrusted", "wasClean", "type", "code", "reason"]);
      console.error("#SC - Socket Error : ", event);
      emitter.addSocketLog(payload);
      emitter.socketConnectionStatus = stateEnum[socket.readyState || "4"];
    };

    const onSocketOpen = () => {
      if (socket?.readyState !== WebSocket.OPEN) {
        setImmediate(() => {
          onSocketOpen();
        }, 1000);
      } else {
        const payload = {
          component: "LAMBDA_SOCKET",
          level: "INFO",
        };
        while (queue.length > 0) {
          console.log("loop queue", queue);
          const event = JSON.parse(queue.pop());
          try {
            payload.level = "INFO";
            payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [${event.action}] : Sending request to socket server.`;
            payload.messageContext = event;
            emitter.addSocketLog(payload);
            socket.send(JSON.stringify(event));
          } catch (error) {
            console.error("#SC - error socket.send : ", error);
            payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [${event.action}] : Error while sending request to server`;
            payload.messageContext.error = error;
            emitter.addSocketLog(payload);
          }
        }
      }
    };
  }
};

export default emitter;
