// Note: This file should be included BEFORE the SmartDial.js and SmartDialCCP.js files

// This is a sample of the configuration object passed to smartdialcore.init():
export const sdConfig = {
  // Fields for Amazon SDK APIs. If provided, these will be set in the Amazon SDK's AWS.config object
  region: ``, // region: The region for the Amazon Connect instance. Example: us-west-2
  identityPoolId: "", // ("us-east-1:5e6a963f-2e1e-4ce8-85b2-cd07c79ba5c0") The value of the trusted Cognito identity for the dialer role. This value is found in the IAM configuration pages. Example: us-west-2:11111111-2222-3333-4444-555555555555

  // SmartDial options
  busyStateName: null, // Name of the state used for the agent after a preview account is rejected (default: "Busy")
  previewStateName: null, // Name of the state used while the agent is previewing an account (default: "Preview")

  // Callback functions for the SmartDial events. If provided, the function is called as described below; see the documentation for parameters
  onConnected: null, // Called when the agent is connected to the dialer
  onDisconnected: null, // Called when the agent is disconnected from the dialer
  onUserNotification: null, // Called when the dialer provides a user-facing notification message
  onCall: null, // Called when an outbound call is transferred to the agent
  onPreview: null, // Called when a preview for an outbound call is presented to the agent
  onAudioNeeded: null, // Called when the client needs to connect audio for the agent
  onAudioNotNeeded: null, // Called when the agent's audio is not needed (or wanted) for outbound calls
  onStateChanged: null, // Called when the agent's data or audio state changes
  onListTransition: null, // Called when the agent transitions to a new list
  onPreviewAccepted: null, // Called when a Preview account has been accepted
  onCallPreview: null, // Called when a preview account should be dialed
  onCallPreviewFailed: null, // Called when a preview dial fails and a dial result should be specified by calling previewDialResult()
  onFlagsChanged: null, // Called when one or more of the agent flags changed
  onAllowTransferAnsweringMachine: null, // Called when an answering machine message can no longer be left
  onActivate: null, // Called when the agent activation status (list group, list name, dial mode) changes
  onError: null,
  ccpConfig: {
    ccpUrl: "",
    loginPopup: false,
    softphone: {
      allowFramedSoftphone: true,
    },
  },
};
